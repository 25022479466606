@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
  }
}


.card {
  
  position: relative;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 40px 60px -6px black;
}



.card-desc {
  display: block;
  font-size: 1.2rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #1f2937;
  overflow-y: scroll;
  
  transition: 0.8s ease;
  color: white;
}

.card:hover .card-desc {
  opacity: 1;
  height: 100%;
  width: 100%;
  overflow-wrap: break-word;
}
/* .image{

  border-radius: 100%;
  background-color: aquamarine;

} */